import * as React from "react"
import Layout from '../templates/page-layout';

export default () => {
  return (
      <Layout title="Contact Us">
        <p>Have a question? Send it to us! We will respond to your message as quickly as possible. Our contact forms are currently offline for maintenance. Please give us a call if you have any questions.</p>
      </Layout>
    
  )
}

